@import-normalize;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Nunito Sans", sans-serif;
}

h1 {
	margin: 0;
}

html,
body,
#root {
	width: 100%;
	height: 100% !important;
}
